import React, { useEffect, useState } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
//import search from '../../assets/icons/search-svgrepo-com.svg'

import { useParams } from 'react-router-dom';

import { fetchData, postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations';


function UpdateAccount() {
    const { id } = useParams();
    const navigate = useNavigate();
    const isLogin = true;

    const selectedOption = "4";
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const [companyData, setCompanyData] = useState({
        idCuenta: 0,
        idCliente: 0,
        idEmpresa: 0,
        nombreUdn: '',
        nombreEmpresa: '',
        nombre: '',
        alias: '',
        clabe: '',
        bloqueada: false,
    });

    //traemos los datos de la cuenta (si es que corresponde a un update)
    useEffect( () => {
        function getAccountData() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
    
            fetchData(process.env.REACT_APP_ACCOUNT_INFO + id + "?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        console.log(error);
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
    
                if (response) {
                    //console.log(response)
                    setCompanyData(response);
                }
            });
        }
        if(id) {
            //console.log(localClabe);
            getAccountData(id);
        }
    }, [id, navigate]);

    const handleChange = (e) => {
        setAlert({tipo: '', msn: '', show: false});
		const { id, value } = e.target;
        //console.log(id,value)
        if( id === 'nombre' && errorIn.maxLength(value, 100, 'error en nombre') ) {
            e.target.value = companyData[id] || '';
            return;
        }
        if( id === 'alias' && errorIn.maxLength(value, 80, 'error en alias') ) {
            e.target.value = companyData[id] || '';
            return;
        }
        if( id  === 'bloqueada' ) {
            setCompanyData((prevData) => ({
                ...prevData,
                [id]: !companyData[id]
            }));
            return;
        }
        // if( id === 'alias' && errorIn.maxLength(value, 20, 'error en alias') ) {
        //     e.target.value = companyData[id] || '';
        //     return;
        // }
		setCompanyData((prevData) => ({
			...prevData,
			[id]: value
		}));
    }

    const updateAccount = () => {
        setAlert({tipo: '', msn: '', show: false});
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        if(errorIn.lengthTrim(companyData.nombre, 1, 100, 'Error')) {
            setAlert({tipo: 'danger', msn: 'Nombre de cuenta, falta o es demasiado largo (100 max)', show: true});
            return;
        }
        if(errorIn.lengthTrim(companyData.alias, 1, 80, 'Error')) {
            setAlert({tipo: 'danger', msn: 'Alias de cuenta, falta o es demasiado largo (80 max)', show: true});
            return;
        }

        const dataToUpdate = {
            id: companyData.idCuenta,
            clabe: companyData.clabe,
            acctName: companyData.nombre,
            acctAlias: companyData.alias,
            bloqueada: companyData.bloqueada
        }
        //console.log('Mandamos a BD: ', dataToUpdate);
        const strPayload = JSON.stringify(dataToUpdate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {account: cryptPayload};

        postData(process.env.REACT_APP_ACCOUNT_INFO + id + "?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                //console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if(response) {
                console.log(response);
                setAlert({tipo: 'success', msn: 'Cuenta actualizada satisfactoriamente', show: true});
                //handleCloseModal();
            }
        });
    }



    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-5 containerTitleAdmin">
                                            <p className='tilteAdmin'>Actualizar Datos de Cuenta</p>
                                        </div>
                                    </div>
                                </div>
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className="bg-container-admin-card">
                                    <div className="row-form-text">
                                        <label className="col-form-label">UDN a la que pertenece la empresa</label>
                                        <input type="text" className="form-control txtControl2" id="idCliente" value={companyData.nombreUdn} disabled />
                                    </div>
                                    <div className="row-form-text">
                                        <label className="col-form-label">Empresa a la cual pertenece la cuenta CLABE</label>
                                        <input type="text" className="form-control txtControl2" id="idEmpresa" value={companyData.nombreEmpresa} disabled />
                                    </div>
                                    <div className="row row-form-text2">
                                        <div className="col-lg-6">
                                            <label className="col-form-label">Nombre identificador de la cuenta</label>
                                            <input type="text" className="form-control txtControl2" id="nombre" value={companyData.nombre} onChange={handleChange}  placeholder=" Nombre para identificar la cuenta"/>
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="col-form-label">Alias de la cuenta</label>
                                            <input type="text" className="form-control txtControl2" id="alias" value={companyData.alias} onChange={handleChange}  placeholder=" Alias de la cuenta"/>
                                        </div>
                                    </div>
                                    <div className="row row-form-text2">
                                        <div className="col-lg-6">
                                            <label className="col-form-label">¿Bloquear cuenta?</label>
                                            <input type="checkbox" id="bloqueada" checked={companyData.bloqueada} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                                        <button type="button" className="btn btn-primary2 btnModal" onClick={updateAccount}>Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

}

export default UpdateAccount
