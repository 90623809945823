import React, { useState, useEffect } from 'react'
import Header from '../../components/Headers/Header'
import Footer from '../../components/Footer/Footer'
import { useParams, useNavigate } from 'react-router-dom';
import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request';

import ReactDOMServer from 'react-dom/server';
import AccountCoverTicket from '../../components/Tickets/AccountCoverTicket';
import iconsp from '../../assets/img/logo.jpeg';

import SendMails from '../../components/Tickets/SendMails';

import '../../css/body.css'
import '../../css/forms.css'

//para ASPNET Error

function AccountCover() {
    const { id } = useParams();
    const isLogin = true;
    const [accountCover, setAccountCover] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ACCOUNT_COVER + id + "/?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (response) {
                    setAccountCover(response)
                } else if (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    }
                }
            });
    }, [id]);

    const queRecibo = () => {
        const printWindow = window.open('', '', 'height=800,width=800');
        const ticketHtml = ReactDOMServer.renderToString(<AccountCoverTicket data={accountCover} id={id} img={iconsp} />); // Convertir el componente a una cadena HTML
        printWindow.document.write(ticketHtml); // Insertar el componente Ticket
        printWindow.document.close();
    }

    const handleGoBack = () => {
        navigate('/accounts');
    };

    // const queRecibo2 = () => {
    //     const data = [
    //         { user: 'Eduardo Isaac', banco: 'BBVA - EPM en BBVA MEXICO', hora: '20:24', fecha: '18/DIC/2023', monto: '$2,000.00', origen: 'tal', destino: 'tal' }
    //     ];
    //     const tipo = 'trasferencia';
    //     const printWindow = window.open('', '', '');
    //     const ticketHtml = ReactDOMServer.renderToString(<SendMails tipo={tipo} data={data} />); // Convertir el componente a una cadena HTML
    //     printWindow.document.write(ticketHtml); // Insertar el componente Ticket
    //     printWindow.document.close();
    // }

    return (
        <div className='body'>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-acconts">
                <div className='containerHome2'>
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-body-container-bg'>
                                <div className="row contentTitle">
                                    <div className="col-sm-8">
                                        <h4 className="subTitle2">Carátula de cuenta {id}</h4>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className='row'>
                                            <div className="col-lg-6 ">
                                                <button className="btn btn-secondary3" type="button" onClick={handleGoBack}>Regresar</button>
                                            </div>
                                            <div className="col-lg-6">
                                                <button className="btnTraspasos2" type="button" onClick={queRecibo} >Imprimir Carátula</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-accountCover">
                                <div className='row'>
                                    <div className='col-6 text-center modal-title'>
                                        <h5>Institución Bancaria:</h5>
                                    </div>
                                    <div className='col-6 text-center modal-title'>
                                        <h5>ASP Integra Opciones</h5>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Cuenta Clabe:</label>
                                    </div>
                                    <div className='col-6 text-center'> 
                                        <label className="coverText">{accountCover.clabe}</label> 
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Registro Federal de Contribuyente:</label>
                                    </div>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">{accountCover.rfc}</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Razón Social</label>
                                    </div>
                                    <div className='col-6 text-center'> 
                                        <label className="coverText">{accountCover.razonSocial}</label>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className='text-center modal-title'>
                                    <h5>Dirección</h5>
                                </div>
                                <hr></hr>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Calle:</label>
                                    </div>
                                    <div className='col-6 text-center'> 
                                        <label className="coverText">{accountCover.callePrincipal}</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Número exterior:</label>
                                    </div>
                                    <div className='col-6 text-center'>  
                                        <label className="coverText">{accountCover.numExterior}</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Interior:</label>
                                    </div>
                                    <div className='col-6 text-center'>  
                                        <label className="coverText">{accountCover.numInterior}</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Colonia:</label>
                                    </div>
                                    <div className='col-6 text-center'> 
                                        <label className="coverText">{accountCover.colonia}</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Ciudad:</label>
                                    </div>
                                    <div className='col-6 text-center'> 
                                        <label className="coverText">{accountCover.ciudad}</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 text-center'>
                                        <label className="coverText">Código Postal:</label>
                                    </div>
                                    <div className='col-6 text-center'> 
                                        <label className="coverText">{accountCover.codPostal}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AccountCover
