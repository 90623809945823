import axios from 'axios';
import Encrypter from './Encrypter';

export const fetchData = (endpoint, headers) => {
  return axios.get(process.env.REACT_APP_BASE_URL + endpoint, headers)
    .then(response => {
      //console.log("R1: " + response)
      const decryptedResponse = Encrypter.aesDecrypt(process.env.REACT_APP_API_SECRET, response.data);
      //console.log("R2: " + decryptedResponse)
      response = JSON.parse(decryptedResponse)
      //console.log("R3: " + response)
      return {response, error: null};
    })
    .catch(error => {
      if(error.code === "ERR_NETWORK") {
        //error = {...error, response: {...error.response, status: 0, message: 'Temporalmente fuera de servicio. ¿Su conexión a internet funciona?'}};
        error = {...error, response: {...error.response, status: 500, data: {errorMesage: '', errors: {errors:[{message: 'Temporalmente fuera de servicio. ¿Su conexión a internet funciona?'}]}}}};      } else if (!error.response) {
        console.log(error);
        error = {...error, response: {...error.response, status: -1}};
      }
      return { response: null, error };
    })
}

export const postData = (endpoint, data, headers, timeout = (10 * 1000) ) => {
    return axios.post(process.env.REACT_APP_BASE_URL + endpoint , data, headers, {timeout: timeout} )
      .then(response => {
      //console.log("R1: " + response)
      //console.log("R2: " + response.data)
      const decryptedResponse = Encrypter.aesDecrypt(process.env.REACT_APP_API_SECRET, response.data)
      //console.log("R3: " + decryptedResponse)
      response = decryptedResponse ? decryptedResponse : response.data
      //console.log("R4: " + response.data)
      //console.log("R5: " + (decryptedResponse ? "Y" : "Data"))
      return { response, error: null };
    })
    .catch(error => {
      if(error.code === "ERR_NETWORK") {
        error = {...error, response: {...error.response, status: 500, data: {errorMesage: '', errors: {errors:[{message: 'Temporalmente fuera de servicio. ¿Su conexión a internet funciona?'}]}}}};
      } else if (!error.response) {
        console.log(error);
        error = {...error, response: {...error.response, status: -1}};
      }
      return { response: null, error };
    })
}

export const putData = (endpoint, data, headers) => {
  return axios.put(process.env.REACT_APP_BASE_URL + endpoint , data, headers )
    .then(response => {
    const decryptedResponse = Encrypter.aesDecrypt(process.env.REACT_APP_API_SECRET, response.data)
    response = decryptedResponse ? decryptedResponse : response.data
    return { response, error: null };
  })
  .catch(error => {
    if(error.code === "ERR_NETWORK") {
      error = {...error, response: {...error.response, status: 500, data: {errorMesage: '', errors: {errors:[{message: 'Temporalmente fuera de servicio. ¿Su conexión a internet funciona?'}]}}}};
    } else if (!error.response) {
      console.log(error);
      error = {...error, response: {...error.response, status: -1}};
    }
    return { response: null, error };
  })
}

export const deleteData = (endpoint, headers) => {
  return axios.delete(process.env.REACT_APP_BASE_URL + endpoint, headers)
    .then(response => {
      //console.log(response);
      if( response.data === 'ok')
        return {response, error: null};
      else
        return { response: null, error: response };
    })
    .catch(error => {
      console.log(error);
      if(error.code === "ERR_NETWORK") {
        //error = {...error, response: {...error.response, status: 0, message: 'Temporalmente fuera de servicio. ¿Su conexión a internet funciona?'}};
        error = {...error, response: {...error.response, status: 500, data: {errorMesage: '', errors: {errors:[{message: 'Temporalmente fuera de servicio. ¿Su conexión a internet funciona?'}]}}}};      } else if (!error.response) {
        //console.log(error);
        error = {...error, response: {...error.response, status: -1}};
      }
      return { response: null, error };
    })
}
