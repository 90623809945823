import React, { useState } from 'react';

import Header from '../../components/Headers/Header';
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral';
import Footer from '../../components/Footer/Footer';
import Message from '../../components/Message/Message';

import { useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';

import SpeiInTransaction from '../../components/Modals/NewSpeiINTransaction';

function SPEIINTransactions() {

    const navigate = useNavigate();
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const [searchClave, setSearchClave] = useState("");
    const [searchResult, setSearchResult] = useState({
        claveRastreo: "",
        fechaOperacion: "",
        estatus: "",
        cuentaOrigen: "",
        cuentaDestino: "",
        numReferencia: "",
        concepto: "",
        monto: 0
    });

    const searchTrx = () => {
        closeAlert();
        if (!searchClave) {
            setAlert({ tipo: 'danger', msn: 'Se requiere una clave de rastreo', show: true });
            return;
        }
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ACCOUNT_TRANSACTIONS + 'speiin/' + searchClave + "?token=" + userData.token, headers)
        .then(({ response, error }) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                } else {
                    setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                }
            }

            if (response && response.length) {
                console.log(response);
                const parsedDate = response[0].fechaOperacion.toString();
                setSearchResult({
                    claveRastreo: response[0].claveRastreo,
                    fechaOperacion: parsedDate.substring(0, 4) + '-' + parsedDate.substring(4, 6) + '-' + parsedDate.substring(6, 8),
                    estatus: response[0].estatus,
                    cuentaOrigen: response[0].cuentaOrdenante,
                    cuentaDestino: response[0].cuentaBeneficiario,
                    numReferencia: response[0].referenciaNumerica,
                    concepto: response[0].conceptoPago,
                    monto: response[0].monto
                });

                /*
                // switch (response[0].estatus) {
                //     case "Solicitado":
                //         setDisabledConfirm(false);
                //         setDisabledCancel(false);
                //         setDisabledRefund(true);
                //         break;
                //     case "Confirmada":
                //         setDisabledConfirm(true);
                //         setDisabledCancel(true);
                //         setDisabledRefund(false);
                //         break;
                //     case "Cancelada":
                //     case "Devolucion":
                //         setDisabledConfirm(true);
                //         setDisabledCancel(true);
                //         setDisabledRefund(true);
                //         break;
                // }
                */
            }
        });
    }

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === "inputClave") {
            //validar que sea una clave de rastreo (o por lo menos un string en la forma SPXXXXXXXXXXXXXXXXXXXXX)
            setSearchClave(value);
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = (msg) => {
        if (msg) {
            setAlert({ tipo: 'success', msn: msg, show: true });
        }
        setShow(false);
    }

    return (
        <div>
            <Header isLogin={true} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={"9"} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className="containerTitleAdmin">
                                        <p className='tilteAdmin'>Transacciones SPEI IN</p>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <div className="row row-form-text0 mb-2">
                                                <div className="col-md-4">
                                                    <div className="col-auto pt-2">
                                                        <button className="btn btn-trans" onClick={() => setShow(true)}>Nueva transacción abonada por ASP</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <div className="row row-form-text0 mb-2">
                                                <div className="col-md-4">
                                                    <div className="col-auto">
                                                        <label className="form-control-plaintext">Clave de Rastreo:</label>
                                                    </div>
                                                    <div className="col-auto">
                                                        <input type="text" className="form-control" id="inputClave" onChange={handleChange} value={searchClave} placeholder="SPXXXXXXXXXXXXXXXXXXXXX" />
                                                    </div>
                                                    <div className="col-auto pt-2">
                                                        <button className="btn btn-trans" onClick={searchTrx}>Buscar Clave</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <h5 className="text-3">Datos de la Transferencia</h5>
                                            <div className="row row-form-text0 mb-2">
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Clave de Rastreo</label>
                                                    <input type="text" className="form-control txtControl" value={searchResult.claveRastreo} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Fecha Solicitud</label>
                                                    <input type="date" className="form-control txtControl" value={searchResult.fechaOperacion} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Estatus de la Transferencia</label>
                                                    <input type="text" className="form-control txtControl" value={searchResult.estatus} readOnly />
                                                </div>
                                            </div>
                                            <div className="row row-form-text0 mb-2">
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Cuenta Origen</label>
                                                    <input type="text" className="form-control txtControl" value={searchResult.cuentaOrigen} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Cuenta Destino</label>
                                                    <input type="text" className="form-control txtControl" value={searchResult.cuentaDestino} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Numero de Referencia</label>
                                                    <input type="text" className="form-control txtControl" value={searchResult.numReferencia} readOnly />
                                                </div>
                                            </div>
                                            <div className="row row-form-text0">
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Concepto</label>
                                                    <input type="text" className="form-control txtControl" value={searchResult.concepto} readOnly />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="col-form-label">Monto</label>
                                                    <input type="text" className="form-control txtControl" value={searchResult.monto} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <SpeiInTransaction show={show} handleClose={handleClose} />
        </div>
    )
}

export default SPEIINTransactions