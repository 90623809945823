import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import { postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import { useNavigate } from 'react-router-dom';
import * as errorIn from '../../utils/validations';

import Message from '../../components/Message/Message';



function EditMultiAccountUser({ show, handleClose, userName, userId, companies, onCompanyAssignChange, onCompanyChargeChange, accountsListAssign, accountsListCharge, resetList }) {

    const navigate = useNavigate();

    function Options({ options }) {
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }
    
    const [localData, setLocalData] = useState({
        empresaIdDeCuentaAsignada: 0,
        cuentaAsignadaId: 0,
        empresaIdDeCuentaCobro: 0,
        cuentaCobroId: 0,
        comision: 0
    });

    useEffect( () => {
        if(show)
            closeAlert();
    }, [show]);
    useEffect( () => {
        // cada que cambie el listado, deberíamos apuntar a la 1a
        if( accountsListAssign.length ) {
            setLocalData((prevData) => ({
                ...prevData,
                cuentaAsignadaId: accountsListAssign[0].id
            }));
        } else {
            setLocalData((prevData) => ({
                ...prevData,
                cuentaAsignadaId: 0
            }));
        }
    }, [accountsListAssign]);

    useEffect( () => {
        // cada que cambie el listado, deberíamos apuntar a la 1a
        if( accountsListCharge.length ) {
            setLocalData((prevData) => ({
                ...prevData,
                cuentaCobroId: accountsListCharge[0].id
            }));
        } else {
            setLocalData((prevData) => ({
                ...prevData,
                cuentaCobroId: 0
            }));
        }
    }, [accountsListCharge]);

    const handleChange = (e) => {
        var { id, value } = e.target;

        if( id === 'empresaIdDeCuentaAsignada' ) {
            onCompanyAssignChange( value );
        }

        if( id === 'empresaIdDeCuentaCobro' ) {
            onCompanyChargeChange( value );
        }

        if( id === 'comision' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            value = localData[id] || '';
            return;
        }

        // cuando cambien los otros datos supongo hay que cambiar setLocalData ... necesito empresaId ahi ???
        setLocalData((prevData) => ({
            ...prevData,
            [id]: value
        }));

    }
    
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const saveData = () => {
        // console.log(localData);
        setAlert({ tipo: '', msn: '', show: false });

        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        if(!userId) {
            setAlert({ tipo: 'danger', msn: 'Cuenta de Usuario Inválida', show: true });
            return;
        }
        if(!localData.cuentaAsignadaId) {
            setAlert({ tipo: 'danger', msn: 'Cuenta Asignada Inválida', show: true });
            return;
        }
        if(!localData.cuentaCobroId) {
            setAlert({ tipo: 'danger', msn: 'Cuenta de Comisión Inválida', show: true });
            return;
        }
        if(isNaN(localData.comision)) {
            setAlert({ tipo: 'danger', msn: 'Monto de comisión Inválida', show: true });
            return;
        } else {
            if(localData.comision === 0) { // solo quiero estar seguro que es 0
                if( !window.confirm('¿Está seguro que quiere una comisión en CERO?') )
                    return;
            }
        }
        const data = {
            userId: userId,
            accountId: localData.cuentaAsignadaId,
            comissionAccountId: localData.cuentaCobroId,
            comision: localData.comision
        }
        const strPayload = JSON.stringify(data);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = { data: cryptPayload };

        postData(process.env.REACT_APP_ACCOUNT_MULTIACCOUNT + "?token=" + userData.token, payload, headers)
        .then(({ response, error }) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors) {
                    setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                } else {
                    console.log(error);
                    setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                }
            }

            if (response) {
                console.log(response);
                resetList();
                //setAlert({ tipo: 'success', msn: 'Datos actualizados correctamente', show: true });
                var message = response.message ? response.message : 'Datos actualizados';
                handleClose(message);

            }
        });
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Cuenta asignada a usuario: {userName}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <Message alert={alert} closeAlert={closeAlert} />
                        <div className="card-body">
                            <div className='row space-fila2'>
                                <label className="form-label">Cuenta Asignada</label>
                                <div className='col-sm-6'>
                                    <label className="form-label">Empresa</label>
                                    <select  className="form-select" id="empresaIdDeCuentaAsignada" onChange={handleChange} value={localData.empresaIdDeCuentaAsignada} >
                                        <Options options={companies} />
                                    </select>
                                </div>
                                <div className='col-sm-6'>
                                    <label className="form-label">Cuenta</label>
                                    <select  className="form-select" id="cuentaAsignadaId" onChange={handleChange} value={localData.cuentaAsignadaId} >
                                        <Options options={accountsListAssign} />
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div className='row space-fila2'>
                                <label className="form-label">Cuenta de Cobro</label>
                                <div className='col-sm-6'>
                                    <label className="form-label">Empresa</label>
                                    <select  className="form-select" id="empresaIdDeCuentaCobro" onChange={handleChange} value={localData.empresaIdDeCuentaCobro} >
                                        <Options options={companies} />
                                    </select>
                                </div>
                                <div className='col-sm-4'>
                                    <label className="form-label">Cuenta</label>
                                    <select  className="form-select" id="cuentaCobroId" onChange={handleChange} value={localData.cuentaCobroId} >
                                        <Options options={accountsListCharge} />
                                    </select>
                                </div>
                                <div className='col-sm-2'>
                                    <label className="form-label">Comisión</label>
                                    <input type="text" className="form-control txtControl" id="comision" onChange={handleChange} value={localData.comision} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary2 btnModal" onClick={saveData} >Guardar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditMultiAccountUser