import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';

import { useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';

function NewUser({show, handleClose, reload}) {

    const navigate = useNavigate();
    const [isSaveDataDisabled, setIsSaveDataDisabled] = useState(false);
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    const [newUserData, setNewUserData] = useState({
        nombre: '',
        correo: '',
        loginPass: '',
        opsPass: '',
        roleId: 0,
        clienteId: 0,
        empresaId: 0,
        cuentaId: 0,
        cuentaCobroId: 0,
        porcentajeCobro: 0,
        multicuentas: []
    });
    const [rolesToSelect, setRolesToSelect] = useState([{
        id: 0,
        value: 'Seleccione el role del usuario'
    }]);
    const [udnsToSelect, setUdnsToSelect] = useState([{
        id: 0,
        value: 'Seleccione la UDN',
        key: 0
    }]);

    useEffect(()=> {
        function getRoles() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
    
            fetchData(process.env.REACT_APP_AUTH_ROLES + "?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
    
                if (response) {
                    const roles = response.roles.map( item => ({
                        id: item.id,
                        value: item.nombre,
                    }));
                    setRolesToSelect(roles);
                    setNewUserData((prevData) => ({
                        ...prevData,
                        roleId: 20 
                    }));
                }
             });
        }
        function getUdns() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
    
            fetchData(process.env.REACT_APP_UDNS + "?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
    
                if (response) {
                    const udns = response.udns.map( item => ({
                        id: item.idUdn,
                        value: item.descripcion,
                        key: item.id
                    }));
                    setUdnsToSelect(udns);
                    setNewUserData((prevData) => ({
                        ...prevData,
                        clienteId: 1268 //lo mismo aqui que en ROLES... lo pongo predefinido por mis pistolas, pero debería ser el primero de la lista
                    }));
                    if(udns[0]) {
                        //console.log('cargamos lista de UDN: ', udns[0]);
                        loadUdnAccounts(udns[0].key);
                    }
                }
             });
        }
        getRoles();
        getUdns();
    }, []);

    const [companiesList, setCompaniesList] = useState([]);
    const loadCompanies = (localClientId, localRoleId) => {
        if(localRoleId === '30' || localRoleId === '50' || localRoleId === '60' || localRoleId === '80' || localRoleId === '90' || localRoleId === '98' || localRoleId === '100') {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            var useLocalId = newUserData.clienteId;
            if(localClientId)
                useLocalId = localClientId;
            fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + useLocalId + "/?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }

                if (response) {
                    const udns = response.empresas.map( item => ({
                        id: item.idEmpresa, 
                        value: item.razonSocial,
                    }));
                    setCompaniesList(udns);
                    if(udns[0]) {
                        setNewUserData((prevData) => ({
                            ...prevData,
                            'empresaId': udns[0].id
                        }));
                        loadAccounts(udns[0].id);
                    }
                }
            });
        } else {
            setCompaniesList([]);
        }
    }

    const [udnAccountsList, setUdnAccountsList] = useState([]);
    const loadUdnAccounts = (clientId) => {
        if((newUserData.clienteId>0) && (newUserData.roleId === '80')) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            //console.log('vamos por cuentas de la udn: ', clientId);
            fetchData(process.env.REACT_APP_UDN_ACCOUNTS_CLIENT + clientId + "/?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }

                if (response) {
                    //console.log('cuentas de cobro: ', response);
                    const cuentas = response.map( item => ({
                        id: item.id, 
                        value: item.accountNumber,
                    }));
                    
                    setUdnAccountsList(cuentas);
                    if(cuentas[0]) {
                        setNewUserData((prevData) => ({
                            ...prevData,
                            'cuentaCobroId': cuentas[0].id //la asigno por si acaso fuera el rol, sino, de todas maneras ni se consideraría
                        }));
                    }
                }
            });
        } else {
            setUdnAccountsList([]);
        }
    }

    const [accountsList, setAccountsList] = useState([]);
    const loadAccounts = (localCompanyId) => {
        if((newUserData.empresaId>0) && (newUserData.roleId === '60' || newUserData.roleId === '98' || newUserData.roleId === '80')) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            var useLocalId = newUserData.empresaId;
            if(localCompanyId)
                useLocalId = localCompanyId;

            fetchData(process.env.REACT_APP_ACCOUNTS_COMPANY + useLocalId + "/?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }

                if (response) {
                    const cuentas = response.map( item => ({
                        id: item.id, 
                        value: item.accountNumber,
                    }));
                    
                    setAccountsList(cuentas);
                    if(cuentas[0]) {
                        setNewUserData((prevData) => ({
                            ...prevData,
                            'cuentaId': cuentas[0].id,
                            //'cuentaCobroId': cuentas[0].id //la asigno por si acaso fuera el rol, sino, de todas maneras ni se consideraría
                        }));
                    }
                }
            });
        } else {
            setAccountsList([]);
        }
    }

    function Options({ options }) {
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }
    const saveData = () => {
        setIsSaveDataDisabled(true);
        setAlert({tipo: '', msn: '', show: false});
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const dataToInsert = {
            id: newUserData.id,
            username: newUserData.nombre,
            email: newUserData.correo,
            pwdAccess: newUserData.loginPass,
            opsPwd: newUserData.opsPass,
            idClient: parseInt(newUserData.clienteId),
            idCompany: newUserData.empresaId ? parseInt(newUserData.empresaId) : null,
            idAccount: newUserData.cuentaId ? parseInt(newUserData.cuentaId) : null,
            idRole: newUserData.roleId.toString(),
            multiAccounts: newUserData.multicuentas,
            depositCost: parseFloat(newUserData.porcentajeCobro),
            chargeAccount: parseInt(newUserData.cuentaCobroId)
        }
        const strPayload = JSON.stringify(dataToInsert);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {userdata: cryptPayload};

        postData(process.env.REACT_APP_AUTH_CREATE_USER + "?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                setIsSaveDataDisabled(false);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                // SI EL ROL ES 80.... MANDAR CREAR REGLAS DE RENTA
                if(response.result === 'ok') {
                    reload();
                }

                setAlert({tipo: 'success', msn: 'Usuario creado correctamente', show: true});
            }
        });
    }

    const handleChangeRole = (e) => {
        const { id, value } = e.target;
        setAlert({tipo: '', msn: '', show: false});

        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
        loadCompanies(newUserData.clienteId, value);
        loadAccounts(newUserData.empresaId);
    }

    const handleChangeUDN = (e) => {
        const { id, value } = e.target;
        setAlert({tipo: '', msn: '', show: false});

        loadCompanies(value, newUserData.roleId);
        // MEGA Parche 240314 - por urgencia, pero hay que encontrar otra solución:
        udnsToSelect.forEach(element => {
            if(parseInt(element.id) === parseInt(value)) {
                loadUdnAccounts(element.key);
            }
        });
        loadAccounts(newUserData.empresaId);
        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }

    const handleChangeCompany = (e) => {
        const { id, value } = e.target;
        setAlert({tipo: '', msn: '', show: false});

        loadAccounts(value);
        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }

    const handleChanges = (e) => {
        const { id, value } = e.target;
        setAlert({tipo: '', msn: '', show: false});
        
        setNewUserData((prevData) => ({
			...prevData,
			[id]: value
		}));
    }
    /*
    const handleChangeMultiAccounts = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        setNewUserData((prevData) => ({
			...prevData,
			'multicuentas': value
		}));
    }
    */
    const resetData = () => {
        setNewUserData({
            nombre: '',
            correo: '',
            loginPass: '',
            opsPass: '',
            roleId: 0,
            clienteId: 0,
            empresaId: 0,
            cuentaId: 0,
            cuentaCobroId: 0,
            porcentajeCobro: 0,
            multicuentas: []
        });
    };

    const handleExitButtonClick = () => {
        resetData();
        handleClose();
        closeAlert();
    };

    return (
        <div>
            <Modal show={show} onHide={() => {handleExitButtonClick();}} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Creación de Usuarios</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <Message alert={alert} closeAlert={closeAlert} />
                        <div className="row-form-text5">
                            <div className="content-label3">
                                <label className="col-form-label">Seleccione el rol del usuario</label>
                                <select className="form-select" id="roleId" onChange={handleChangeRole} value={newUserData.roleId} >
                                    <Options options={rolesToSelect} />
                                </select>
                            </div>
                        </div>
                        <div className="row-form-text5">
                            <label className="col-form-label">Seleccione la UDN sobre la que hará operaciones el usuario</label>
                            <select className="form-select" id="clienteId" onChange={handleChangeUDN} value={newUserData.clienteId} >
                                <Options options={udnsToSelect} />
                            </select>
                        </div>
                        {(() => {
                            switch (newUserData.roleId) {
                                case '20':
                                case '40':
                                case '95':
                                    return <div className="row-form-text5">
                                            <label className="col-form-label">Rol a nivel UDN, favor de ingresar los datos del usuario</label>
                                        </div>
                                case '30':
                                case '50':
                                case '90':
                                case '100':
                                    return <div className="row-form-text5">
                                        <label className="col-form-label">Seleccione la Empresa de la cual dependerá el nuevo usuario</label>
                                        <select className="form-select" id="empresaId" onChange={handleChangeCompany} value={newUserData.empresaId} >
                                            <Options options={companiesList} />
                                        </select>
                                    </div>;
                                case '60':
                                case '98':
                                    return <div className="row-form-text5">
                                            <label className="col-form-label">Seleccione la Empresa de la cual dependerá el nuevo usuario</label>
                                            <select className="form-select" id="empresaId" onChange={handleChangeCompany} value={newUserData.empresaId} >
                                                <Options options={companiesList} />
                                            </select>
                                            <label className="col-form-label">Seleccione la cuenta a operar por parte del nuevo usuario</label>
                                            <select className="form-select" id="cuentaId" onChange={handleChanges} value={newUserData.cuentaId} >
                                                <Options options={accountsList} />
                                            </select>
                                        </div> 
                                case '80':
                                    return null;
                                    /*
                                    return <div>
                                        <div className="row-form-text5">
                                            <label className="col-form-label">Seleccione la Empresa proveedora de las cuentas</label>
                                            <select className="form-select" id="empresaId" onChange={handleChangeCompany} value={newUserData.empresaId} >
                                                <Options options={companiesList} />
                                            </select>
                                        </div>
                                        <div className="row-form-text5">
                                            <label className="col-form-label">Datos de la(s) cuenta(s) que operará el usuario</label>
                                            <select className="form-select" id="multicuentas" multiple aria-label="Multiple select example" value={newUserData.multicuentas} onChange={handleChangeMultiAccounts} >
                                                <Options options={accountsList} />
                                            </select>
                                        </div>

                                        <div className="row-form-text5">
                                            <label className="col-form-label">Seleccione la cuenta a la cual realizarán los abonos de las comisiones cobradas</label>
                                            <select className="form-select" id="cuentaCobroId" onChange={handleChanges} value={newUserData.cuentaCobroId} >
                                                <Options options={udnAccountsList} />
                                            </select>
                                        </div>
                                        
                                        <div className="row row-form-text">
                                            <div className="col-lg-7">
                                                <label className="col-form-label">Porcentaje de comisión</label>
                                                <input type="text" id="porcentajeCobro" onChange={handleChanges} className="form-control txtControl"/> 
                                            </div>
                                        </div>
                                    </div>;*/
                                default:
                                    return null; // Otra opción si tipo no coincide con ninguno de los casos
                            }
                        })()}
                        <div className="row row-form-text">
                            <div className="col-lg-7">
                                <label className="col-form-label">Nombre del usuario</label>
                            </div>
                            <div className="col-lg-5 content-input2">
                                <input type="text" className="form-control txtControl" id="nombre" onChange={handleChanges} value={newUserData.nombre} />
                            </div>
                        </div>
                        <div className="row row-form-text">
                            <div className="col-lg-7">
                                <label className="col-form-label">Correo electrónico</label>
                            </div>
                            <div className="col-lg-5 content-input2">
                                <input type="text" className="form-control txtControl" id="correo" onChange={handleChanges} value={newUserData.correo} />
                            </div>
                        </div>
                        <div className="row row-form-text">
                            <div className="col-lg-7">
                                <label className="col-form-label">Ingrese la contraseña de acceso</label>
                            </div>
                            <div className="col-lg-5 content-input2">
                                <input type="password" className="form-control txtControl" id="loginPass" onChange={handleChanges} value={newUserData.loginPass} />
                            </div>
                        </div>
                        <div className="row row-form-text2">
                            <div className="col-lg-7">
                                <label className="col-form-label">Ingrese la contraseña de operaciones</label>
                            </div>
                            <div className="col-lg-5 content-input2">
                                <input type="password" className="form-control txtControl" id="opsPass" onChange={handleChanges} value={newUserData.opsPass} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer">
                        <button disabled={isSaveDataDisabled} type="button" className="btn btn-primary2 btnModal" onClick={saveData}>Guardar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NewUser
