import React, { useEffect, useState } from 'react';

import Header from '../../components/Headers/Header';
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral';
import Footer from '../../components/Footer/Footer';
import search from '../../assets/icons/search-svgrepo-com.svg';
import Table from '../../components/Table/Table';
import SearchModal from '../../components/Modals/SearchModal';
import Message from '../../components/Message/Message';
import CargoDetails from '../../components/Modals/CargoDetails';
import ImproperChargeClaim from '../../components/Modals/ImproperChargeClaim';
import icon from '../../assets/icons/three-dots-vertical.svg';
import detain from '../../assets/icons/file-text.svg';

import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';

function ManageTransactions() {
    const navigate = useNavigate();
    const selectedOption = "7";
    const [transactions, setTransactions] = useState([]);

    const tableHeaders = ['Fecha', 'Núm transacción', 'Tipo Operación', 'Tarjetahabiente', 'Núm tarjeta', 'Monto', 'Acciones'];
    const tableStyles = ['centeredStyle', 'centeredStyle', 'centeredStyle', 'textStyle', 'centeredStyle', 'amountStyle', 'centeredStyle'];

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });
    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    //Revisar detalles del cargo
    const [showDetails, setShowDetails] = useState(false);
    const handleDetailsClose = () => setShowDetails(false);
    const handleDetailsShow = () => {
        setShowDetails(true);
    };

    //Reclamo de cargo indebido
    const [showImproper, setShowImproper] = useState(false);
    const handleImproperClose = () => setShowImproper(false);
    const handleImproperShow = () => setShowImproper(true);

    const menuIcon = [
        { icon: detain, url: '', modal: handleDetailsShow, clave: '' },
    ]

    //Modal Buscar 
    const tipo = "TransaccionesTDC";
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [searchData, setSearchData] = useState({
        byLastFour: '',
        byCardHolder: ''
    });

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const [selected, setSelectedRow] = useState(null);
    const handleRowSelect = (row) => {
        // console.log(row);
        setSelectedRow(row);
    }

    function executeSearch() {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        console.log(searchData);
        getCardTransaction();
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
    }

    // const data = [
    //     { tarjeta: '1234', numTransaction: '123456789', fecha: '01-08-2024', monto: '$ 1,550.00', menu: '' }
    // ];

    const getCardTransaction = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        var searchQuery = '';
        if(searchData.byLastFour)
            searchQuery = '&bylastfour=' + searchData.byLastFour;
        if(searchData.byCardHolder)
            searchQuery = searchQuery + '&byCardHolder=' + searchData.byCardHolder;

        fetchData(process.env.REACT_APP_ADMIN_CARDS + "operations/?token=" + userData.token + searchQuery + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
        .then(({ response, error}) => {
            if (error) {
                //console.log(error);
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else {
                    if( error.code === "ERR_NETWORK" ) {
                        setAlert({tipo: 'danger', msn: '¿Su conexión a internet funciona? De lo contrario, contacte de inmediato al administrador', show: true});
                    } else {
                        console.log(error);
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
            }

            if (response) {
                //console.log(response);
                const opsData = response.operations.map(item => ({
                    fecha: item.fechaOperacion,
                    numTransaction: item.numTransaccion,
                    tipoOps: item.tipoOps,
                    tarjetahabiente: item.tarjetahabiente,
                    tarjeta: item.cardFour,
                    monto: item.montoCargo,
                    establecimiento: item.establecimiento,
                    lugar: item.ciudad,
                    status: item.estatus,
                    statusCode: item.statusCode,
                    statusPom: item.pomeloStatus,
                    statusSites: item.sitesStatus
                }));
                setPagination((prevData) => ({
                    ...prevData,
                    totalItems: response.totalRows
                }));
                setTransactions( opsData );
            }
        });
    }

    useEffect(() => {
        //setTransactions(data);
        getCardTransaction();
    }, [pagination.currentPage, pagination.itemsPerPage]);

    return (
        <div>
            <Header isLogin={true} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-7 containerTitleAdmin">
                                            <p className='tilteAdmin'>Administración de Transacciones TDC</p>
                                        </div>
                                        <div className="col-sm-4 contenBtnAdmin">

                                        </div>
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={transactions} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} menuIcon={menuIcon} isMenu={true} onSelect={handleRowSelect} tipo='admin' from='udns'
                                        currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems / pagination.itemsPerPage)} handleDetailsShow={handleDetailsShow} showImproper={handleImproperShow} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <CargoDetails show={showDetails} handleClose={handleDetailsClose} handleImproperShow={handleImproperShow} trxData={selected} />
            <ImproperChargeClaim show={showImproper} handleClose={handleImproperClose} data={selected} />
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />
        </div>
    )
}

export default ManageTransactions