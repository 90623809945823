import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import Message from '../../components/Message/Message';
import { getInstitutionCode } from '../../services/institution';
import * as errorIn from '../../utils/validations';

import { useNavigate } from 'react-router-dom';
import { postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter'

function SpeiInTransaction({ show, handleClose }) {

    const date = new Date();
    const [speiData, setSpeiData] = React.useState({
        claveRastreo: '',
        fechaOperacion: date.toISOString().split('T')[0],
        rfcOrigen: '',
        nombreOrigen: '',
        cuentaOrigen: '',
        bancoOrigen: '',
        nombreDestino: '',
        cuentaDestino: '',
        concepto: '',
        monto: '0',
        iva: '0',
        numReferencia: ''
    });

    const navigate = useNavigate();
    const [alert, setAlert] = React.useState(false);
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const [confirmDisabled, setConfirmDisabled] = useState(false);

    const setDestinationBank = (act) => {
        const bankCode = getInstitutionCode(act.substring(0, 3));
        speiData.bancoOrigen = bankCode; //no jalaba
        setSpeiData((prevData) => ({
            ...prevData,
            bancoOrigen: bankCode
        }));
    }

    const handleChange = (e) => {
        var { id, value } = e.target;

        if (id === 'cuentaOrigen' && value !== '' && errorIn.integer(value)) {
            e.target.value = speiData[id] || '';
            return;
        }
        if (id === 'cuentaOrigen' && value.length === 18) {
            if (errorIn.confirmDV(value)) {
                setAlert({ tipo: 'danger', msn: 'Verifique el número de cuenta, dígito verificador incorrecto', show: true });
                return;
            }
            setDestinationBank(value.substring(0, 3));
        }

        if (id === 'cuentaDestino' && value !== '' && errorIn.integer(value)) {
            e.target.value = speiData[id] || '';
            return;
        }
 
        if (id === 'monto' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = speiData[id] || '';
            return;
        }
        if (id === 'iva' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = speiData[id] || '';
            return;
        }

        if (id === 'numReferencia' && value !== '' && errorIn.integer(value)) {
            e.target.value = speiData[id] || '';
            return;
        }

        if( id === 'rfcOrigen' || id === 'nombreOrigen' || id === 'nombreDestino' || id === 'concepto' ) {
            //limpiamos todo lo que tiene que ser solo texto, y que no se haya limpiado antes
            value = errorIn.sanitizeText(value);
        }

        setSpeiData({ ...speiData, 
            [id]: value
        });
    };

    const handleSave = () => {
        setConfirmDisabled(true);
        setAlert({ tipo: '', msn: '', show: false });
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } };

        const data = {
            trxType: 2,
            claveRastreo: speiData.claveRastreo,
            fechaOperacion: speiData.fechaOperacion,
            rfcOrigen: speiData.rfcOrigen,
            nombreOrigen: speiData.nombreOrigen,
            accountFrom: speiData.cuentaOrigen,
            toBankCode: speiData.bancoOrigen,
            beneficiary: speiData.nombreDestino,
            beneficiaryRfc: 'ND',
            accountTo: speiData.cuentaDestino,
            concept: speiData.concepto,
            transferAmount: speiData.monto,
            iva: speiData.iva,
            refNumber: speiData.numReferencia
        };

        const payObjStr = JSON.stringify(data);
        const payment = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, payObjStr);
        const body = { data: payment };

        postData(process.env.REACT_APP_ACCOUNT_MANUAL_SPEIIN + "?token=" + userData.token, body, headers)
        .then(({ response, error }) => {
            setConfirmDisabled(false);
            if (response) {
                handleClose('Abono registrado correctamente');
            } else if (error) {
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                } else if (responseErrors !== undefined) {
                    setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                } else {
                    console.log(error);
                    setAlert({ tipo: 'danger', msn: 'Error de sistema desconocido', show: true });
                }
                return;
            }
        });
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Nuevo SPEI IN</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula containerCard">
                        <Message alert={alert} closeAlert={closeAlert} />

                        <div className="card-body">
                            <h5 className="text-3">Datos de la Transferencia</h5>
                            <div className="row row-form-text0 mb-2">
                                <div className="col-lg-6">
                                    <label className="col-form-label">Clave de Rastreo</label>
                                    <input type="text" className="form-control txtControl" id='claveRastreo' maxLength='30' value={speiData.claveRastreo} onChange={handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">Fecha Operación</label>
                                    <input type="date" className="form-control txtControl" id='fechaOperacion' value={speiData.fechaOperacion} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row row-form-text0 mb-2">
                                <div className="col-lg-6">
                                    <label className="col-form-label">RFC Origen</label>
                                    <input type="text" className="form-control txtControl" id='rfcOrigen' maxLength='13' value={speiData.rfcOrigen} onChange={handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">Nombre Origen</label>
                                    <input type="text" className="form-control txtControl" id='nombreOrigen' maxLength='40' value={speiData.nombreOrigen} onChange={handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">Cuenta Origen</label>
                                    <input type="text" className="form-control txtControl" id='cuentaOrigen' maxLength='18' value={speiData.cuentaOrigen} onChange={handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">Banco Origen</label>
                                    <input type="text" className="form-control txtControl" id='bancoOrigen' value={speiData.bancoOrigen} readOnly />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">Nombre Destino</label>
                                    <input type="text" className="form-control txtControl" id='nombreDestino' maxLength='40' value={speiData.nombreDestino} onChange={handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">Cuenta Destino</label>
                                    <input type="text" className="form-control txtControl" id='cuentaDestino' maxLength='18' value={speiData.cuentaDestino} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row row-form-text0">
                                <div className="col-lg-6">
                                    <label className="col-form-label">Concepto</label>
                                    <input type="text" className="form-control txtControl" id='concepto' maxLength='20' value={speiData.concepto} onChange={handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">Numero de Referencia</label>
                                    <input type="text" className="form-control txtControl" id='numReferencia' maxLength='7' value={speiData.numReferencia} onChange={handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">Monto</label>
                                    <input type="text" className="form-control txtControl" id='monto' value={speiData.monto} onChange={handleChange} />
                                </div>
                                <div className="col-lg-6">
                                    <label className="col-form-label">IVA</label>
                                    <input type="text" className="form-control txtControl" id='iva' value={speiData.iva} onChange={handleChange} />
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleSave} type="button" disabled={confirmDisabled} className="btn btn-primary2 btnModal">Guardar transacción SPEI IN</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SpeiInTransaction