import React, { useEffect, useState } from 'react'

import Header from '../../components/Headers/Header';
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral';
import Footer from '../../components/Footer/Footer';
import Table from '../../components/Table/Table';
import EditMultiAccountUser from '../../components/Modals/AddAccountUsers';

import { useNavigate } from 'react-router-dom';
import { fetchData, deleteData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import { useParams } from 'react-router-dom';

import Message from '../../components/Message/Message';

function UpdateUsers() {
    const { id } = useParams();
    const navigate = useNavigate();

    const tableHeaders = ['Cuenta asignada', 'Cuenta Cobro', '% Cobro', 'Acciones'];
    const tableStyles = ['accountStyle', 'accountStyle', 'centeredStyle', 'centeredStyle'];

    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });

    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const [newUserData, setNewUserData] = useState({
        id: 0,
        nombre: '',
        correo: '',
        roleId: 0,
        roleName: '',
        clienteId: 0,
        udnName: ''
    });

    const [multiAccounts, setMultiAccounts] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [accountsListAssign, setAccountsListAssign] = useState([]);
    const [accountsListCharge, setAccountsListCharge] = useState([]);
    const loadAccounts = (localCompanyId, setList) => {
        setList([]);
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }
    
        fetchData(process.env.REACT_APP_ACCOUNTS_COMPANY + localCompanyId + "/?token=" + userData.token, headers)
        .then(({ response, error }) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                } else {
                    console.log(error);
                    setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                }
            }
    
            if (response) {
                const cuentas = response.map(item => ({
                    id: item.id,
                    value: item.accountNumber
                }));
                setList(cuentas);
            }
        });
    }

    const loadUserAccounts = ( uid ) => {
        if( !uid )
            uid = id;

        //traemos datos de las multi cuentas
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ACCOUNT_MULTIACCOUNT + uid + "?token=" + userData.token, headers)
        .then(({ response, error }) => {
            if (error) {
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                } else {
                    setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                }
            }

            if (response) {

                const listMultiAccounts = response.map( item => ({
                    clabeCuentaAsignada: item.clabeCuentaAsignada,
                    clabeCuentaCobro: item.clabeCuentaCobro,
                    comisionAbono: item.comision,
                    idEmpresaCtaAsignada: item.idEmpresaCtaAsignada,
                    idEmpresaCtaComision: item.idEmpresaCtaComision,
                    idDeCuentaAsignada: item.idCuentaAsignada,
                    idDeCuentaCobro: item.idCuentaCobroComision
                }));
                setMultiAccounts( listMultiAccounts );
            }
        });
    }

    const isMenu = false;
    const [showAdd, setShowAdd] = useState(false);
    const handleCloseAdd = (msg) => {
        if(msg) {
            setAlert({ tipo: 'warning', msn: msg, show: true });
        }
        setShowAdd(false);
    }
    const handleShowAdd = () => {
        setShowAdd(true);
    }

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const handleChangeCompany = (newValue) => {
        setAlert({ tipo: '', msn: '', show: false });
        //console.log('voy por nuevas cuentas de una empresa: ', newValue );
        loadAccounts(newValue, setAccountsListAssign);
    }

    const handleChangeChargeCompany = (newValue) => {
        setAlert({ tipo: '', msn: '', show: false });
        //console.log('voy por nuevas cuentas de una empresa: ', newValue );
        loadAccounts(newValue, setAccountsListCharge);
    }

    const handleChanges = (e) => {
        const { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }

    const handleRowSelect = (row) => {
        setAlert({ tipo: '', msn: '', show: false });
        // console.log(row);
        if(row.clabeCuentaAsignada) {
            const message = '¿Seguro que quiere eliminar la asignación de la cuenta ' + row.clabeCuentaAsignada + '?';
            if( window.confirm(message) ) {
                //console.log(row);

                const data = {
                    uid: newUserData.id,
                    aid: row.idDeCuentaAsignada,
                    cid: row.idDeCuentaCobro
                }
                const dataCryp = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify( data ));

                //console.log('mando a borrar');
                const userData = JSON.parse(localStorage.getItem('userData'))
                const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
                const headers = { 
                    headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token },
                    data: { data: dataCryp }
                }
                deleteData(process.env.REACT_APP_ACCOUNT_MULTIACCOUNT + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        //console.log(error);
                        var responseErrors;
                        if( error.response && error.response.data && error.response.data.errors ) {
                            responseErrors = error.response.data.errors.errors;
                        }
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            console.log(error);
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }
    
                    if (response) {
                        //console.log(response);
                        setAlert({ tipo: 'success', msn: 'Datos actualizados correctamente', show: true });
                        loadUserAccounts();
                    }
                });
            
            }
        }
    }

    useEffect(() => {
        const loadCompanies = ( localClientId ) => {
            setCompaniesList([]);
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }
    
            fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + localClientId + "/?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (error) {
                    console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        console.log(error);
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                }
    
                if (response) {
                    // si empresas tiene al menos una, usamos la primera para "llenar" la lista de cuentas
                    if(response.empresas) {
                        var item = response.empresas[0];
                        loadAccounts( item.idEmpresa,  setAccountsListAssign);
                        loadAccounts( item.idEmpresa,  setAccountsListCharge);
                    }
                    const empresas = response.empresas.map(item => ({
                        id: item.idEmpresa, //idUdn,
                        value: item.razonSocial,
                        //clientId: item.id
                    }));
                    setCompaniesList(empresas);
                }
            });
        }
    
        function getUser() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_AUTH_USERS + id + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        // console.log(response);
                        setNewUserData({
                            id: response.idUsuario,
                            nombre: response.nombre ? response.nombre : '',
                            correo: response.email ? response.email : '',
                            roleId: response.idRole ? response.idRole : 0,
                            roleName: response.nombreRole ? response.nombreRole : '',
                            clienteId: response.idUdn ? response.idUdn : 0,
                            udnName: response.nombreUdn ? response.nombreUdn : ''
                        });

                        loadCompanies( response.idUdn );
                        loadUserAccounts( response.idUsuario );
                    }
                });
        }
        getUser();
    }, [ id ]);



    return (
        <div>
            <Header isLogin={true} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={"3"} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className='card-sub-title-bg2'>
                                <div className='row'>
                                    <div className="col-sm-8 containerTitleAdmin">
                                        <p className='tilteAdmin'>Actualizar Usuario -  {newUserData.nombre}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-admin-card">
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className="card card-caratula space-update">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className='space-fila'>
                                                    <label className="form-label">Nombre del usuario</label>
                                                    <input type="text" className="form-control txtControl" id="nombre" disabled onChange={handleChanges} value={newUserData.nombre} />
                                                </div>
                                                <div className='space-fila'>
                                                    <label className="form-label">Correo electrónico</label>
                                                    <input type="text" className="form-control txtControl" id="correo" disabled onChange={handleChanges} value={newUserData.correo} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div>
                                                    <label className="form-label">UDN a la que pertenece el usuario:</label>
                                                    <input type="text" className="form-control txtControl" id="clienteId" disabled onChange={handleChanges} value={newUserData.udnName} />
                                                </div>
                                                <div>
                                                    <label className="form-label">Rol del usuario</label>
                                                    <input type="text" className="form-control txtControl" id="roleId" disabled onChange={handleChanges} value={newUserData.roleName} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-caratula space-update">
                                    <div className="card-body space-label">
                                        <div className='row'>
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label">Cuentas</label>
                                                <p><a className="add" href="#" onClick={handleShowAdd}>+</a></p>
                                            </div>
                                            <div className='cuentasTable'>
                                                <Table headers={tableHeaders} data={multiAccounts} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} isMenu={isMenu} onSelect={handleRowSelect} from='multiuser'
                                                    currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems / pagination.itemsPerPage)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditMultiAccountUser show={showAdd} handleClose={handleCloseAdd} userName={newUserData.nombre} companies={companiesList} userId={newUserData.id} accountsListAssign={accountsListAssign} 
                            accountsListCharge={accountsListCharge} onCompanyAssignChange={handleChangeCompany} onCompanyChargeChange={handleChangeChargeCompany} resetList={loadUserAccounts} /*acctData={selected}*/ />
            <Footer />
        </div>
    )
}

export default UpdateUsers
