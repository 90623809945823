import React, { useEffect, useState } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
import search from '../../assets/icons/search-svgrepo-com.svg'
import SearchModal from '../../components/Modals/SearchModal'
import Table from '../../components/Table/Table'
import Edit from '../../assets/icons/edit_icon.svg'
//import Delete from '../../assets/icons/trash.svg'
import NewUser from '../../components/Modals/NewUser';

import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';
import BulkLoad from '../../components/Modals/BulkLoad'

function AdministerUsers() {
    const isLogin = true;
    //const isAdmin = true;
    const tipo = "usuarios";

    const tableHeaders = ['Nombre', 'Correo electrónico', 'Rol', 'UDN / Empresa', 'Acciones'];
    const tableStyles = ['textStyle', 'textStyle', 'textStyle', 'textStyle', 'centeredStyle'];

    const isMenu = true;
    const selectedOption = "3";
    //const [selected ,setSelectedRow] = useState(null);

    // const handleRowSelect = (row) => {
    //     //console.log('Selected: ', row);
    //     setSelectedRow(row);
    // }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showAdd, setShowAdd] = useState(false);
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => setShowAdd(true);

    const [showBulkLoad, setShowBulkLoad] = useState(false);
    const handleShowBulk = () => setShowBulkLoad(true);
    const handleCloseBulk = () => setShowBulkLoad(false);

    // const [showEdit, setShowEdit] = useState(false);
    //const handleCloseEdit = () => setShowEdit(false);
    // const handleShowEdit = (item) => {
    //     setShowEdit(true);
    // }

    /// en general todos los usuarios van a estar con esta ruta para su "administración" y "modificación" ... PERO... los multi cuenta, deberan estar en :
    /// / ... entonces. en la tabla, tenrdrá una validación por el role que tenga el usuario 
    const menuIcon = [
        { icon: Edit, url: '/actualizarusuario', modal: '', clave: '' },
    ]
    const menuIcon2 = [
        { icon: Edit, url: '/actualizarmulticuenta', modal: '', clave: '' },
    ]

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    //const [userAccounts, setUserAccounts] = useState([]);
    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });
    const [updateData, setUpdateData] = useState(1);
    const reload = () => {
        setUpdateData(updateData + 1);
    }

    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const [searchData, setSearchData] = useState({
        roleId: '',
        companyId: '',
        byUserMail: '',
    });

    function loadUsers() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }
        var searchQuery = '';
        if( searchData.byUserMail )
            searchQuery = '&bymail=' + searchData.byUserMail;
        if( searchData.roleId )
            searchQuery = searchQuery + '&roleId=' + searchData.roleId;
        if( searchData.companyId )
            searchQuery = searchQuery + '&companyId=' + searchData.companyId;

        fetchData(process.env.REACT_APP_AUTH + "?token=" + userData.token + searchQuery + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
            .then(({ response, error }) => {
                if (error) {
                    //console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                }

                if (response) {
                    //['Correo electrónico', 'Nombre', 'Rol','Empresa','Acciones'];
                    // console.log(response);
                    const usersResponse = response.usuarios.map(user => ({
                        nombre: user.nombreUsuario,
                        mail: user.email,
                        role: user.nombreRole,
                        empresa: (user.nombreEmpresa ? user.nombreEmpresa : user.nombreCliente),
                        id: user.idUsuario,
                        // idCliente: user.idCliente,
                        // idEmpresa: user.idEmpresa,
                        // idCuenta: user.idCuenta,
                        idRole: user.idRole
                    }));
                    setPagination((prevData) => ({
                        ...prevData,
                        totalItems: response.totalRows
                    }));
                    setTableData(usersResponse);
                    //setUserAccounts(response);
                }
            });
    }

    useEffect(() => {
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
        if (updateData)
            loadUsers();
    }, [updateData, pagination.currentPage])

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    function executeSearch() {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        loadUsers();
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
    }

    // async function doSearch(currentPage, otherParams, setFunc) {
    //     if(currentPage===undefined)
    //         currentPage = pagination.currentPage;

    //     const userData = JSON.parse(localStorage.getItem('userData'));
    //     const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
    //     const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

    //     fetchData(process.env.REACT_APP_ACCOUNTS + "?token=" + userData.token + "&items=" + pagination.itemsPerPage + "&page=" + currentPage + otherParams, headers)
    //     .then( ({ response, error}) => {
    //         if (response) {
    //             setFunc(response.cuentas);
    //             setPagination((prevData) => ({
    //                 ...prevData,
    //                 totalItems: response.totalRows
    //             }));
    //         } else if (error) {
    //             const responseErrors = error.response.data.errors.errors;
    //             if (error.response.status === 401) {
    //                 //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
    //                 localStorage.removeItem('userData');
    //                 navigate('/');
    //             } else if (responseErrors!==undefined) {
    //                 setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
    //             } else {
    //                 console.log(error);
    //                 setAlert({tipo: 'danger', msn: 'Error en el systema', show: true});
    //             }
    //             return;
    //         }
    //     });
    // }

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-4 containerTitleAdmin">
                                            <p className='tilteAdmin'>Administración de Usuarios</p>
                                        </div>
                                        <div className="col-sm-7 contenBtnAdmin">
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <button className="btn btn-primary" type="button" onClick={handleShowAdd}>Nuevo Usuario</button>
                                                </div>
                                                <div className='col-sm-8'>
                                                    <button className="btn btn-secondary5" type="button" onClick={handleShowBulk}>Carga masiva de Usuarios (de tarjeta)</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={tableData} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} menuIcon={menuIcon} menuIcon2={menuIcon2} isMenu={isMenu} /*onSelect={handleRowSelect}*/ from='adminusr'
                                        currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems / pagination.itemsPerPage)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />
            <NewUser show={showAdd} handleClose={handleCloseAdd} reload={reload} />
            <BulkLoad show={showBulkLoad} handleClose={handleCloseBulk} />
        </div>
    )
}

export default AdministerUsers
