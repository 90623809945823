import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request';
import { getOperationDescription } from '../../services/operationType';
import { useNavigate } from 'react-router-dom';
import '../../css/body.css';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import MenuBody from '../../components/Body/Menubody/MenuBody';
import TableMovements from '../../components/Table/TableMovements';
import SearchModal from '../../components/Modals/SearchModal';
import AdditionalInformation from '../../components/Modals/AdditionalInformation';
import Message from '../../components/Message/Message';
import MovementTicket from '../../components/Tickets/MovementTicket';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import search from '../../assets/icons/search-svgrepo-com.svg';
import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import ReactDOMServer from 'react-dom/server';

var xlsx = require('xlsx');

function Movements() {
    const { id } = useParams();
    const [operations, setOperations] = useState([]);
    const [addInfo, setAddInfo] = useState([]);
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const tipo = "movimientos";
    const [showProgress, setShowProgress] = useState(false);


    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    function getToday() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    const userData = JSON.parse(localStorage.getItem('userData'));
    const [searchData, setSearchData] = useState({
        byDateIn: getToday(),
        byDateOut: getToday(),
        byConcept: '',
        byTrackId: '',
        byToAccountBin: ''
    });

    const [pagination, setPagination] = useState({
        itemsPerPage: 25,
        totalItems: 1,
        currentPage: 1
    });
    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    const doSearch = (currentPage) => {
        if (currentPage === undefined)
            currentPage = pagination.currentPage;

        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ACCOUNT_OPS + id + "/?token=" + userData.token + "&fechain=" + searchData['byDateIn'] + "&fechaout=" + searchData['byDateOut'] + "&concept=" + searchData['byConcept'] + "&trackid=" + searchData['byTrackId'] + "&accountto=" + searchData['byToAccountBin'] + "&items=" + pagination.itemsPerPage + "&page=" + currentPage, headers)
            .then(({ response, error }) => {
                if (response) {
                    const movimientos = response.movimientos.map(movement => {
                        const movs = {
                            date: movement.fechaSolicitud,
                            operation: getOperationDescription(movement.operacion),
                            account: movement.cuentaTercero,
                            description: movement.conceptoPago,
                            amount: movement.operacion === 'A' || movement.operacion === 'D' ? movement.montoAbono : "-" + movement.montoCargo,
                            balance: movement.saldoAnterior,
                            tipoOperacion: movement.operacion,
                            nombreTercero: movement.nombreTercero,
                            institucionTercero: movement.institucion,
                            claveRastreo: movement.claveRastreo,
                            reference: movement.referenciaNumerica,
                            nombreCliente: movement.nombreCliente,
                            cuentaCliente: movement.cuentaCliente //,
                            // monto: movement.montoAbono //montoCargo
                        };

                        const aInfo = {
                            claveRastreo: movement.claveRastreo,
                            operacion: movement.operacion,
                            idExterno: movement.idExterno,
                            comentarios: movement.comentarios
                        };

                        return { movs, aInfo };
                    });

                    setPagination((prevData) => ({
                        ...prevData,
                        totalItems: response.totalRows
                    }));
                    setOperations(movimientos.map(item => item.movs));
                    setAddInfo(movimientos.map(item => item.aInfo));
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                    return;
                }
            });
    }

    function executeSearch() {
        setAlert({ tipo: '', msn: '', show: false });
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        //ver comentario en accounts en una linea similar
        doSearch(1);
    }

    useEffect(() => {
        doSearch(pagination.currentPage);
        // Agregar el evento de escucha al montar el componente
        window.addEventListener('resize', handleResize);

        // Limpiar el evento de escucha al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [pagination.currentPage]);

    useEffect(() => {
        setAlert({ tipo: 'success', msn: 'Estos son los movimientos del día de hoy. Para ver otras operaciones, de clic en el boton de filtrar (lupa de la derecha)', show: true });
    }, []);

    const tableHeaders = ['Fecha', 'Operación', 'Cuenta de Terceros', 'Concepto', 'Monto Operación', 'Saldo', 'Acciones'];
    const tableStyles = ['dateStyle', 'centeredStyle', 'accountStyle', 'textStyle', 'amountStyle', 'amountStyle', 'centeredStyle'];

    const breadcrumbItems = [
        { text: userData.userName, link: '/accounts' },
        { text: 'Cuentas', link: '/accounts' },
        { text: 'Movimientos', link: '' },
        { text: id, link: '' }
    ];
    const isLogin = true;

    const [selected, setSelectedRow] = useState({ index: null, data: null });

    const handleRowSelect = (row) => {
        setSelectedRow(row);
    }

    const handleUpdateAddInfo = (index, info) => {
        setAddInfo(prevAddInfo => {
            prevAddInfo[index] = info;
            return prevAddInfo;
        });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const closeAlert = (alert) => {
        setAlert(alert);
    };

    const [showInfo, setShowInfo] = useState(false);
    const handleCloseInfo = () => setShowInfo(false);
    const handleShowInfo = () => setShowInfo(true);

    const descargaExcel = () => {
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }
        fetchData(process.env.REACT_APP_ACCOUNT_COVER + id + "/?token=" + userData.token, headers)
        .then(({ response, error }) => {
            if (response) {
                var rsp = response;
                // literal, hago una búsqueda con lo que tengo, pero sin paginar, y me traigo "todo" para enviarlo al excel
                fetchData(process.env.REACT_APP_ACCOUNT_OPS + id + "/?token=" + userData.token + "&order=1&fechain=" + searchData['byDateIn'] + "&fechaout=" + searchData['byDateOut'] + "&concept=" + searchData['byConcept'] + "&trackid=" + searchData['byTrackId'] + "&accountto=" + searchData['byToAccountBin'], headers)
                .then(({ response, error }) => {
                    if (response) {

                        var newArray = [];
                        response.movimientos.map(data => {
                            const obj = {
                                date: data.fechaOperacion,
                                operation: getOperationDescription(data.operacion),
                                description: data.conceptoPago,
                                account: data.cuentaTercero,
                                institucionTercero: data.institucion,
                                nombreTercero: data.nombreTercero,
                                amountDebit: data.montoCargo,
                                amountCredit: data.montoAbono,
                                balance: data.saldoAnterior,
                                refNum: data.referenciaNumerica,
                                claveRastreo: data.claveRastreo,
                                idExterno: data.idExterno,
                                comentarios: data.comentarios
                            };

                            newArray.push(obj);
                            return { obj };
                        });

                        const wb = xlsx.utils.book_new();
                        //var ws = wb.Sheets["Movimientos"];
                        var ws_data = [["SitesPay - Resumen de operaciones"],["Empresa", rsp.razonSocial], ["RFC", rsp.rfc], ["Fecha inicial", searchData['byDateIn']], ["Fecha final", searchData['byDateOut']], ["Cuenta clabe", id], ["Identificador de la cuenta", rsp.nombreCuenta]];
                        var ws = xlsx.utils.aoa_to_sheet(ws_data);
                        ws["!cols"] = [{ wch: 25 }, { wch: 25 }, { wch: 40 }, { wch: 20 }, { wch: 25 }, { wch: 40 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 35 }, { wch: 25 }, { wch: 60 }];

                        xlsx.utils.sheet_add_aoa(ws, [["Fecha de Operación", "Operación", "Concepto", "Cuenta Destino", "Institución Bancaria", "A nombre de", "Monto Cargo", "Monto Abono", "Saldo Final", "Referencia Numérica", "Clave de Rastreo", "Identificador local", "Comentarios"]], { origin: "A9" });
                        xlsx.utils.sheet_add_json(ws, newArray, {skipHeader: true, origin: "A10"});
                        xlsx.utils.book_append_sheet(wb, ws, "Movimientos");

                        let buffer = xlsx.write(wb, { bookType: 'xlsx', compression: true, type: 'buffer' });

                        let blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });

                        let link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = "SitesPay - Movimientos " + id + " " + searchData['byDateIn'];
                        link.click();
                        URL.revokeObjectURL(link.href);
                        
                    } else if (error) {
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                        return;
                    }
                });
            } else if (error) {
                console.log(error);
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                } else {
                    setAlert({ tipo: 'danger', msn: 'Error al descargar archivo', show: true });
                }
            }
        });
    }

    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    // Función para manejar el cambio del checkbox de seleccionar todos
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedRows(operations.map((_, index) => index));
        } else {
            setSelectedRows([]);
        }
    };

    // Función para manejar el cambio de un checkbox individual
    const handleRowSelectChange = (index) => {
        const newSelectedRows = selectedRows.includes(index)
            ? selectedRows.filter(i => i !== index)
            : [...selectedRows, index];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === operations.length);
    };

    const getSelectedValues = () => {
        const selectedData = selectedRows.map(index => operations[index]);
        return selectedData;
    };

    const [progress, setProgress] = useState(0);

    const handleResultsPrint = async () => {
        setProgress(0);
        setShowProgress(true);
        const items = getSelectedValues();
        const zip = new JSZip();
        // Generar PDFs en lotes para evitar el consumo excesivo de memoria
        const batchSize = 10;
        const totalTransactions = items.length;
        let processedTransactions = 0;

        for (let i = 0; i < items.length; i += batchSize) {
            const batch = items.slice(i, i + batchSize);
            const pdfPromises = batch.map((item, index) => generatePDF(item, i + index));

            try {
                const pdfBlobs = await Promise.all(pdfPromises);
                pdfBlobs.forEach((pdfBlob, index) => {
                    zip.file(`Comprobante - Transaccion_${i + index + 1}.pdf`, pdfBlob);
                    processedTransactions++;
                    const newProgress = (processedTransactions / totalTransactions) * 100;
                    setProgress(newProgress);
                });
            } catch (error) {
                console.error('Error al generar PDFs:', error);
            }
        }

        try {
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            setShowProgress(false);
            setProgress(0);
            saveAs(zipBlob, `Comprobantes.zip`);
        } catch (error) {
            console.error('Error al generar el archivo ZIP:', error);
        }
    };

    const generatePDF = async (transaction, index) => {
        const element = document.createElement('div');
        const ticketPdfHtml = ReactDOMServer.renderToString(<MovementTicket data={[transaction]} />);
        element.innerHTML = ticketPdfHtml;

        const options = {
            margin: 1,
            filename: `Comprobante - Transaccion_${index + 1}.pdf`,
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        return html2pdf().from(element).set(options).outputPdf('blob');
    };

    return (
        <div className='body'>
            <Header isLogin={isLogin} />
            <div className='container-fluid2 position-absolute space-acconts'>
                <div className="containerHome2">
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-sub-title-bg'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <MenuBody items={breadcrumbItems} handleShow={handleShow} />
                                    </div>
                                    <div className='col-5'>
                                        <div className='row'>
                                            <div className='col-6 btnExcel'>
                                                <p><a href="#" className="link-light" onClick={handleResultsPrint}>Descargar Comprobantes </a></p>
                                            </div>
                                            <div className='col-6 btnExcel'>
                                                <p ><a href="#" className="link-light" onClick={descargaExcel}>Descargar operaciones</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-1 content-row'>
                                        <button className='btnSearchModal' type="button" onClick={handleShow}>
                                            <img className='imgSearch' src={search} alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-body">
                                <Message alert={alert} closeAlert={closeAlert} />
                                {showProgress && (
                                    <ProgressBar progress={parseInt(progress)} texto={"Generando reportes"} />
                                )}
                                <TableMovements headers={tableHeaders} data={operations} styles={tableStyles}
                                    currentPage={pagination.currentPage} setCurrentPage={setCurrentPage}
                                    totalPages={Math.ceil(pagination.totalItems / pagination.itemsPerPage)}
                                    onSelect={handleRowSelect} addInfo={addInfo} handleShow={handleShowInfo}
                                    selectAll={selectAll} handleSelectAllChange={handleSelectAllChange}
                                    selectedRows={selectedRows} handleRowSelectChange={handleRowSelectChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <AdditionalInformation data={selected} onChange={handleUpdateAddInfo} show={showInfo} handleClose={handleCloseInfo} />
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />
        </div>
    )
}

export default Movements
