import React, { useState } from 'react';
import '../../css/body.css';
import { useParams, useNavigate } from 'react-router-dom';
//import Accordion from 'react-bootstrap/Accordion';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import download from '../../assets/icons/download.svg';
import axios from 'axios';
import Encrypter from '../../services/Encrypter'


function AccountStatus() {
    const { id } = useParams();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/accounts');
    };

    const meses = [
        { key: '01', value: 'Enero' }, 
        { key: '02', value: 'Febrero' },
        { key: '03', value: 'Marzo' },
        { key: '04', value: 'Abril' },
        { key: '05', value: 'Mayo' },
        { key: '06', value: 'Junio' },
        { key: '07', value: 'Julio' },
        { key: '08', value: 'Agosto' },
        { key: '09', value: 'Septiembre' },
        { key: '10', value: 'Octubre' },
        { key: '11', value: 'Noviembre' },
        { key: '12', value: 'Diciembre' }
    ];
    const [monthSelected, setMonth] = useState('01');

    const years = ['2025','2024','2023'];
    const [yearSelected, setYear] = useState('2025');

    const handleDownload = () => {

        const data = '{"data": {"cuenta": "' + id + '"}}';
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }
        const url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DOCTOS_STATEMENTS + '/' + yearSelected + '/' + monthSelected; 
        axios.post( url + '/?token=' + userData.token, data, headers )
        .then(response => {
            const pdfFile = new Blob([response.data], { type: "application/pdf" });
            const pdfFileURL = URL.createObjectURL(pdfFile);
            var popUpWindow = window.open("" , "_blank");
            popUpWindow.location.href = pdfFileURL;
        })
        .catch(error => {
            console.log(error);
            if(error.code === "ERR_NETWORK") {
                error = {...error, response: {...error.response, status: 500, data: {errorMesage: '', errors: {errors:[{message: 'Temporalmente fuera de servicio. ¿Su conexión a internet funciona?'}]}}}};
            } else if (!error.response) {
                console.log(error);
                error = {...error, response: {...error.response, status: -1}};
            }
            // return { response: null, error };
        })
    };

    const [isVisible, setIsVisible] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const handleChange = (e) => {
        var { id, value } = e.target;
        if (id === 'year') {
            setYear(value);
        }
        if (id === 'month') {
            setMonth(value);
        }
    }

    const handleSearchs = () => {
        setIsVisible(true);
        setSearchData('Estado de cuenta del mes');
        //metodo para buscar el documento
    };

    return (
        <div className='body'>
            <Header isLogin={true} />
            <div className="container-fluid2 position-absolute space-acconts">
                <div className='containerHome2'>
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-body-container-bg'>
                                <div className="row contentTitle">
                                    <div className="col-sm-10">
                                        <h4 className="subTitle2">Estado de cuenta</h4>
                                    </div>
                                    <div className="col-sm-2">
                                        <button className="btn btn-secondary3" type="button" onClick={handleGoBack}>Regresar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-accountCover">
                                <div>
                                    <div className='row space-reporte'>
                                        <div className='col-sm-4'>
                                            <label className="col-form-label">Seleccionar Año</label>
                                            <select className='form-select txtControl' id='year' value={yearSelected} onChange={handleChange} >
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-4'>
                                            <label className="form-label">Seleccionar Mes</label>
                                            <select className='form-select txtControl' id='month' value={monthSelected} onChange={handleChange} >
                                                {meses.map((i) => (
                                                    <option key={i.key} value={i.key}>
                                                        {i.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-sm-4'>
                                            <label className="form-label"></label>
                                            <div className="d-grid gap-2 mx-auto buttonAdmin">
                                                <button className="btnLogin" type="button" onClick={handleSearchs}>Buscar</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    {isVisible === true ? (
                                        <div className='space-reporte'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Mes del Corte</th>
                                                        <th>Documento</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='table-td'>
                                                        <td>
                                                            <label className='table-label'>{searchData}</label>
                                                        </td>
                                                        <td>
                                                            <a className="link-table " href="#" onClick={handleDownload}>
                                                                <img src={download} alt="" className='iconButton' />
                                                                Descargar
                                                            </a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className='space-reporte'>

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AccountStatus